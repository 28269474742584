<template>
  <base-section
    id="hjalpvivillhem"
  >
    <base-section-heading title="Hjälp, vi vill hem!">
    <p>Hjälp, vi vill hem! handlar om Sten och Sandra som har strandat på en öde ö. Trots att de till en början har svårt att nå fram till varandra så lyckas de lära sig 
      att samarbeta i ett gemensamt försök att ta sig bort från ön.
    </p>
    <p>Sandra har varit på ön så länge hon kan minnas och har till slut gjort den till sitt hem. Plötsligt en dag hamnar Sten av misstag på ön och börjar desperat att 
      leta efter ett sätt att ta sig hem. Sandra tar glatt chansen att umgås med en annan människa igen medan Sten försöker förstå varför hon inte vill följa med honom 
      bort från ön. Deras olika personligheter leder till konflikt. Med glimten i ögat behandlas ämnen som mänskliga relationer, kommunikation och samarbete - ämnen som 
      i dagens digitaliserade samhälle paradoxalt nog är både enklare och mer komplicerade än någonsin tidigare. För att leva tillsammans med andra människor behöver barn 
      tidigt lära sig förstå, hantera och bemöta sitt eget och andras känsloliv.
    <p>
     Pjäsen vänder sig framförallt till barn mellan 5 och 9 år (men även deras vuxna ser den med behållning.)
    </p>
    <p>
     Manus: Johan Millving<br/>
     Regi: Fredrik Zeijlon Lundin<br/>
     Scenografi: Tom Richmond<br/>
    </p>
    </base-section-heading>

    <v-container>
      <v-row
        align="center"
        justify="center"
      >
      <v-col align="center">
      <v-responsive
        class="mx-auto"
      >
        <base-img
          max-width="1000"
          :src="require(`@/assets/hjalphem.jpg`)"
        />
        <p>Foto: Anna Jensen</p>
    </v-responsive>
  </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProChart',

    data: () => ({
    }),
  }
</script>
